/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PointOfSaleOrderFee from './PointOfSaleOrderFee';
import PointOfSaleOrderPayment from './PointOfSaleOrderPayment';
import PointOfSaleOrderReservation from './PointOfSaleOrderReservation';

/**
 * The PointOfSaleOrder model module.
 * @module model/PointOfSaleOrder
 * @version v1
 */
class PointOfSaleOrder {
    /**
     * Constructs a new <code>PointOfSaleOrder</code>.
     * @alias module:model/PointOfSaleOrder
     */
    constructor() { 
        
        PointOfSaleOrder.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PointOfSaleOrder</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PointOfSaleOrder} obj Optional instance to populate.
     * @return {module:model/PointOfSaleOrder} The populated <code>PointOfSaleOrder</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PointOfSaleOrder();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('orderNumber')) {
                obj['orderNumber'] = ApiClient.convertToType(data['orderNumber'], 'String');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('notes')) {
                obj['notes'] = ApiClient.convertToType(data['notes'], 'String');
            }
            if (data.hasOwnProperty('locationId')) {
                obj['locationId'] = ApiClient.convertToType(data['locationId'], 'Number');
            }
            if (data.hasOwnProperty('saleDate')) {
                obj['saleDate'] = ApiClient.convertToType(data['saleDate'], 'Date');
            }
            if (data.hasOwnProperty('reservations')) {
                obj['reservations'] = ApiClient.convertToType(data['reservations'], [PointOfSaleOrderReservation]);
            }
            if (data.hasOwnProperty('fees')) {
                obj['fees'] = ApiClient.convertToType(data['fees'], [PointOfSaleOrderFee]);
            }
            if (data.hasOwnProperty('payments')) {
                obj['payments'] = ApiClient.convertToType(data['payments'], [PointOfSaleOrderPayment]);
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
PointOfSaleOrder.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
PointOfSaleOrder.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
PointOfSaleOrder.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
PointOfSaleOrder.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
PointOfSaleOrder.prototype['id'] = undefined;

/**
 * @member {String} orderNumber
 */
PointOfSaleOrder.prototype['orderNumber'] = undefined;

/**
 * @member {String} tenantId
 */
PointOfSaleOrder.prototype['tenantId'] = undefined;

/**
 * @member {String} notes
 */
PointOfSaleOrder.prototype['notes'] = undefined;

/**
 * @member {Number} locationId
 */
PointOfSaleOrder.prototype['locationId'] = undefined;

/**
 * @member {Date} saleDate
 */
PointOfSaleOrder.prototype['saleDate'] = undefined;

/**
 * @member {Array.<module:model/PointOfSaleOrderReservation>} reservations
 */
PointOfSaleOrder.prototype['reservations'] = undefined;

/**
 * @member {Array.<module:model/PointOfSaleOrderFee>} fees
 */
PointOfSaleOrder.prototype['fees'] = undefined;

/**
 * @member {Array.<module:model/PointOfSaleOrderPayment>} payments
 */
PointOfSaleOrder.prototype['payments'] = undefined;






export default PointOfSaleOrder;

