/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The PointOfSaleOrderSummary model module.
 * @module model/PointOfSaleOrderSummary
 * @version v1
 */
class PointOfSaleOrderSummary {
    /**
     * Constructs a new <code>PointOfSaleOrderSummary</code>.
     * @alias module:model/PointOfSaleOrderSummary
     */
    constructor() { 
        
        PointOfSaleOrderSummary.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PointOfSaleOrderSummary</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PointOfSaleOrderSummary} obj Optional instance to populate.
     * @return {module:model/PointOfSaleOrderSummary} The populated <code>PointOfSaleOrderSummary</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PointOfSaleOrderSummary();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('orderNumber')) {
                obj['orderNumber'] = ApiClient.convertToType(data['orderNumber'], 'String');
            }
            if (data.hasOwnProperty('notes')) {
                obj['notes'] = ApiClient.convertToType(data['notes'], 'String');
            }
            if (data.hasOwnProperty('orderTotal')) {
                obj['orderTotal'] = ApiClient.convertToType(data['orderTotal'], 'Number');
            }
            if (data.hasOwnProperty('paymentTotal')) {
                obj['paymentTotal'] = ApiClient.convertToType(data['paymentTotal'], 'Number');
            }
            if (data.hasOwnProperty('balance')) {
                obj['balance'] = ApiClient.convertToType(data['balance'], 'Number');
            }
            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'String');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
PointOfSaleOrderSummary.prototype['id'] = undefined;

/**
 * @member {String} orderNumber
 */
PointOfSaleOrderSummary.prototype['orderNumber'] = undefined;

/**
 * @member {String} notes
 */
PointOfSaleOrderSummary.prototype['notes'] = undefined;

/**
 * @member {Number} orderTotal
 */
PointOfSaleOrderSummary.prototype['orderTotal'] = undefined;

/**
 * @member {Number} paymentTotal
 */
PointOfSaleOrderSummary.prototype['paymentTotal'] = undefined;

/**
 * @member {Number} balance
 */
PointOfSaleOrderSummary.prototype['balance'] = undefined;

/**
 * @member {String} dateCreated
 */
PointOfSaleOrderSummary.prototype['dateCreated'] = undefined;

/**
 * @member {String} dateLastModified
 */
PointOfSaleOrderSummary.prototype['dateLastModified'] = undefined;

/**
 * @member {String} createdBy
 */
PointOfSaleOrderSummary.prototype['createdBy'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
PointOfSaleOrderSummary.prototype['lastModifiedBy'] = undefined;






export default PointOfSaleOrderSummary;

