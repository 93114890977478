/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The AdminPointOfSaleSearchRequest model module.
 * @module model/AdminPointOfSaleSearchRequest
 * @version v1
 */
class AdminPointOfSaleSearchRequest {
    /**
     * Constructs a new <code>AdminPointOfSaleSearchRequest</code>.
     * @alias module:model/AdminPointOfSaleSearchRequest
     */
    constructor() { 
        
        AdminPointOfSaleSearchRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AdminPointOfSaleSearchRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AdminPointOfSaleSearchRequest} obj Optional instance to populate.
     * @return {module:model/AdminPointOfSaleSearchRequest} The populated <code>AdminPointOfSaleSearchRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AdminPointOfSaleSearchRequest();

            if (data.hasOwnProperty('locationId')) {
                obj['locationId'] = ApiClient.convertToType(data['locationId'], 'Number');
            }
            if (data.hasOwnProperty('orderNumber')) {
                obj['orderNumber'] = ApiClient.convertToType(data['orderNumber'], 'String');
            }
            if (data.hasOwnProperty('productClassificationId')) {
                obj['productClassificationId'] = ApiClient.convertToType(data['productClassificationId'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} locationId
 */
AdminPointOfSaleSearchRequest.prototype['locationId'] = undefined;

/**
 * @member {String} orderNumber
 */
AdminPointOfSaleSearchRequest.prototype['orderNumber'] = undefined;

/**
 * @member {Number} productClassificationId
 */
AdminPointOfSaleSearchRequest.prototype['productClassificationId'] = undefined;

/**
 * @member {String} tenantId
 */
AdminPointOfSaleSearchRequest.prototype['tenantId'] = undefined;






export default AdminPointOfSaleSearchRequest;

