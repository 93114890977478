import AdminPointOfSaleApi from "@/generatedapiclients/src/api/AdminPointOfSaleApi.js";

export default class AdminPointOfSaleService {
  constructor(tenantId) {
    this._tenantId = tenantId;
    this._adminPointOfSaleApi = new AdminPointOfSaleApi();
  }

  async getPointOfSaleOrder(pointOfSaleOrderId) {
    const response = await this._adminPointOfSaleApi.v1TenantTenantIdAdminAdminPointOfSaleOrderPointOfSaleOrderIdGet(
      pointOfSaleOrderId,
      this._tenantId
    );
    return response;
  }

  async addPointOfSaleOrder(pointOfSaleOrder) {
    const response = await this._adminPointOfSaleApi.v1TenantTenantIdAdminAdminPointOfSaleOrderPost(
      this._tenantId,
      {
        pointOfSaleOrder
      }
    );
    return response;
  }

  async updatePointOfSaleOrder(pointOfSaleOrder) {
    const response = await this._adminPointOfSaleApi.v1TenantTenantIdAdminAdminPointOfSaleOrderPut(
      this._tenantId,
      {
        pointOfSaleOrder
      }
    );
    return response;
  }

  async getPointOfSaleOrders(adminPointOfSaleSearchRequest) {
    const response = await this._adminPointOfSaleApi.v1TenantTenantIdAdminAdminPointOfSaleOrdersPost(
      this._tenantId,
      {
        adminPointOfSaleSearchRequest
      }
    );
    return response;
  }
}
