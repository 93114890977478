/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AdminPointOfSaleSearchRequest from '../model/AdminPointOfSaleSearchRequest';
import PointOfSaleOrder from '../model/PointOfSaleOrder';
import PointOfSaleOrderResponse from '../model/PointOfSaleOrderResponse';
import PointOfSaleOrderSummaryIEnumerableResponse from '../model/PointOfSaleOrderSummaryIEnumerableResponse';

/**
* AdminPointOfSale service.
* @module api/AdminPointOfSaleApi
* @version v1
*/
export default class AdminPointOfSaleApi {

    /**
    * Constructs a new AdminPointOfSaleApi. 
    * @alias module:api/AdminPointOfSaleApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {Number} pointOfSaleOrderId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PointOfSaleOrderResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminPointOfSaleOrderPointOfSaleOrderIdGetWithHttpInfo(pointOfSaleOrderId, tenantId) {
      let postBody = null;
      // verify the required parameter 'pointOfSaleOrderId' is set
      if (pointOfSaleOrderId === undefined || pointOfSaleOrderId === null) {
        throw new Error("Missing the required parameter 'pointOfSaleOrderId' when calling v1TenantTenantIdAdminAdminPointOfSaleOrderPointOfSaleOrderIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminPointOfSaleOrderPointOfSaleOrderIdGet");
      }

      let pathParams = {
        'pointOfSaleOrderId': pointOfSaleOrderId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PointOfSaleOrderResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminPointOfSale/order/{pointOfSaleOrderId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} pointOfSaleOrderId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PointOfSaleOrderResponse}
     */
    v1TenantTenantIdAdminAdminPointOfSaleOrderPointOfSaleOrderIdGet(pointOfSaleOrderId, tenantId) {
      return this.v1TenantTenantIdAdminAdminPointOfSaleOrderPointOfSaleOrderIdGetWithHttpInfo(pointOfSaleOrderId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/PointOfSaleOrder} opts.pointOfSaleOrder 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PointOfSaleOrderResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminPointOfSaleOrderPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['pointOfSaleOrder'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminPointOfSaleOrderPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PointOfSaleOrderResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminPointOfSale/order', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/PointOfSaleOrder} opts.pointOfSaleOrder 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PointOfSaleOrderResponse}
     */
    v1TenantTenantIdAdminAdminPointOfSaleOrderPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminPointOfSaleOrderPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/PointOfSaleOrder} opts.pointOfSaleOrder 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PointOfSaleOrderResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminPointOfSaleOrderPutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['pointOfSaleOrder'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminPointOfSaleOrderPut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PointOfSaleOrderResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminPointOfSale/order', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/PointOfSaleOrder} opts.pointOfSaleOrder 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PointOfSaleOrderResponse}
     */
    v1TenantTenantIdAdminAdminPointOfSaleOrderPut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminPointOfSaleOrderPutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminPointOfSaleSearchRequest} opts.adminPointOfSaleSearchRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PointOfSaleOrderSummaryIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminPointOfSaleOrdersPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['adminPointOfSaleSearchRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminPointOfSaleOrdersPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PointOfSaleOrderSummaryIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminPointOfSale/orders', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminPointOfSaleSearchRequest} opts.adminPointOfSaleSearchRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PointOfSaleOrderSummaryIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminPointOfSaleOrdersPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminPointOfSaleOrdersPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
